import React from 'react'

const Grid3 = (props) => {

    return (
        <div className="grid3">
            {props.children}


        </div>
    )
}

export default Grid3
