import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

import BackgroundImage from "gatsby-background-image"

import SEO from "../components/SEO"
import Layout from "../components/layout/layout"
import Grid3 from "../components/grid/grid3"
import { CardWrapper } from "../components/cardMain/CardMain"
import CardMain from "../components/cardMain/CardMain"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "main_image.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      img3: file(relativePath: { eq: "main_image3.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      neo: file(relativePath: { eq: "NEO.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      sdr: file(relativePath: { eq: "SDR.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      dez: file(relativePath: { eq: "dez1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const main_image = data.bg.childImageSharp.fluid
  const main_image3 = data.img3.childImageSharp.fluid
  const neo = data.neo.childImageSharp.fluid
  const sdr = data.sdr.childImageSharp.fluid
  const dez = data.dez.childImageSharp.fluid

  return (
    <div>
      <SEO title="Home" slug="" />
      <Layout>
        <BackgroundImage
          className="grid3 grid-image baner"
          fluid={main_image}
          backgroundColor={`#FFFFFF`}
        >
          <div className="grid-1-2 corner-bottom-right baner-wrapper">
            <div className="baner-text">
              <h5>materiały stomatologiczne | środki dezynfekcyjne</h5>
              <h1>
                Hurtownia Stomatologiczna
                <br /> Infinity Medical
              </h1>
              <h2>
                U nas odnajdziesz wszystko to czego <br />
                potrzebujesz w swoim gabinecie.
              </h2>
              <h2>
                Szukasz rewelacyjnej jakości w niskiej cenie?
                <br />
                Dobrze trafiłeś!
              </h2>
            </div>
          </div>

          <div className="grid-3 corner-bottom-left baner-phone-wrapper">
            <div className="baner-phone">
              <p>Zadzwoń i zamów!</p>
              <p>+48 570 133 366</p>
              <p>+48 883 670 370</p>
            </div>
          </div>
        </BackgroundImage>
        {/*
      <Grid3>
        <div className="grid-1-2 corner-top-right mat-stom">
          <Link to="/mat_stom" className="mat-stom-link">
            <h3>Materiały stomatologiczne</h3>

            <div className="grid2">
              <div className="mat-stom-product">
                <h4>NEO Spectra</h4>
                <Img fluid={neo}
                  objectFit="contain"
                  alt="cover"
                  className="image-product" />
              </div>

              <div className="mat-stom-product">
                <h4>SDR Plus</h4>
                <Img fluid={sdr}
                  objectFit="contain"
                  alt="cover"
                  className="image-product" />
              </div>

            </div>
          </Link>
        </div>

        <div className="grid-3 corner-top-left dez">
          <Link to="/dezynfekcja" className="dez-link">
            <h3>Środki dezynfekcyjne</h3>
            <div className="mat-stom-product">
              <Img fixed={dez}
                objectFit="contain"
                alt="cover"
                className="image-product" />
            </div>
          </Link>
        </div>
      </Grid3>
      */}
        <Grid3>
          <div className="grid-1-2 corner-top-right" />
          <div className="grid-3 corner-top-left" />
        </Grid3>

        <CardWrapper>
          <CardMain
            fluid={neo}
            header1="Materiały Stomatologiczne"
            header2="Neo Spectra"
            text="Dowiedz się więcej o Neo Spectra."
          />
          <CardMain
            fluid={sdr}
            header1="Materiały Stomatologiczne"
            header2="SDR Plus"
            text="Dowiedz się więcej o SDR Plus."
          />
          <CardMain
            fluid={dez}
            header1="Materiały dezynfekcyjne"
            header2="SAIKO"
            to="/dezynfekcja"
            text="Dowiedz się więcej o Materiałach dezynfekcyjnych"
          />
        </CardWrapper>

        <Grid3>
          <Img
            fluid={main_image3}
            objectFit="cover"
            alt="cover"
            className="main-image grid-1"
          />
          <div className="grid-2-3 grid-text">
            <p>
              Hurtownia Stomatologiczna Zdrowy Ząbek została stworzona z myślą o
              tych stomatologach, którzy cenią sobie profesjonalizm, świetne
              produkty w niskich cenach i miła atmosferę współpracy.
            </p>
            <p>
              <b>Mamy w sprzedaży między innymi:</b>
            </p>
            <ul>
              <li>materiały stomatologiczne</li>
              <li>odzież jednorazową</li>
            </ul>
            <p>
              <b>
                Jeżeli szukasz świetnej jakości kompozytów, środków do
                dezynfekcji albo odzieży jednorazowej dobrze trafiłeś. Zadzwoń i
                zamów!
              </b>
            </p>
          </div>
        </Grid3>
      </Layout>
    </div>
  )
}

export default IndexPage

/*
<Img fluid={main_image}
objectFit="cover"
alt="cover"
className="main-image" />

<Img fluid={main_image_mob}
objectFit="cover"
alt="cover"
className="main-image-mob" />
*/
