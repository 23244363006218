import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import { Container } from '../utils/utils'
import Button from '../button/Button';

export const CardWrapper = styled(Container)`
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;

    margin: 5rem auto 7rem auto;

    ${media.lessThan("small")`
    grid-template-columns: repeat(1, 1fr);
    margin: 0 auto 2rem auto;
    `}
 
    ${media.between("small", "medium")`
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto 2rem auto;
    `}

    ${media.between("medium", "large")`
    grid-template-columns: repeat(3, 1fr);
    `}

    ${media.between("large", "huge")`
    grid-template-columns: repeat(3, 1fr);
    `}
 
    ${media.greaterThan("huge")`
    grid-template-columns: repeat(3, 1fr);
    `}
`;

const Card = styled(BackgroundImage)`
    font-size: 20px;
    color: white;
    background-color: #e1eff3;
    transition: all .4s ease-in-out;
    
    ${media.lessThan("small")`
    height: 400px;
    `}
 
    ${media.between("small", "medium")`
    height: 350px;
    `}

    ${media.between("medium", "large")`
    height: 380px;
    `}

    ${media.between("large", "huge")`
    height: 400px;
    `}
 
    ${media.greaterThan("huge")`
    height: 500px;
    `}
    
    div {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 0;
        display: flex;
        flex-direction: column;
        transition: all .4s ease-in-out;

        a {
            position: absolute;
            bottom: 2rem;
            opacity: 0;
            left: 50%;
            transform: translateX(-50%); 

            ${media.lessThan("small")`
            opacity: 1;
            `}
        }

        p {
            opacity: 0;
            color: white;
        }
    }

    h6, h4 {
        margin: 0 2rem;
    }

    h6 {
        margin-top: 2rem;
    }

    &::before,
    &::after {
        background-size: contain !important;
        background-position-x: center !important;
        background-position-y: 4rem !important;
    }

    &:hover {
        div {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 80%;
            background-color: rgba(0, 29, 46, 0.699);
            padding: 2rem;
        }

        a {
            opacity: 1;
        }

        p {
            opacity: 1;
            color: white;
            font-weight: 500;
        }
    }
`;

const CardMain = (props) => (
    <Card
        backgroundColor={`#FFFFFF`}
        fluid={props.fluid}>
        <h6>{props.header1}</h6>
        <h4>{props.header2}</h4>
        <div>
            <p>{props.text}</p>
            <Button to={props.to || '/mat_stom'}>
                Więcej
            </Button>
        </div>
    </Card>
);

export default CardMain;